










import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsCodeSelect extends Vue {
  codeId: string = '1';

  changeCurrency () {
    this.$Api.member.setCurrency(this.codeId).then((result) => {
      this.$message({
        message: this.$t('changeCurSuccess') as string,
        type: 'success'
      });
    }).catch((error) => {
      console.log(error);
    });
  }
}
