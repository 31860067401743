



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Menu from '@/components/business/pc/header/InsMenu.vue';
import handleGroup from '@/components/business/pc/header/InsHandleGroup.vue';
import switchGroup from '@/components/business/pc/header/InsSwitchGroup.vue';

@Component({
  components: {
    Menu,
    handleGroup,
    switchGroup
  }
})
export default class InsHeader extends Vue {
  @Prop() private msg!: string;
  // private hhh: any = this.$t('test');

  getMenu () {
    this.$Api.promotion.getMenu().then((result) => {
      this.$store.dispatch('setHeaderMenus', result.ReturnValue.HeaderMenus);
      this.$store.dispatch('setFooterMenus', result.ReturnValue.FooterMenus);
    }).catch((error) => {
      console.log(error);
    });
  }
  To () {
    this.$router.push('/');
  }
  private changLange (lang) {
    this.$i18n.locale = lang;
    // console.log(this.hhh, this.$t('test'),'test');
    localStorage.setItem('locale', lang);
  }

  mounted () {
    this.getMenu();
  }
  get Lang () {
    return this.$store.state.lang;
  }
  @Watch('Lang')
  LangChange () {
    this.$Api.member.setUILanguage(this.Lang).then((result) => {
      this.getMenu();
    }).catch((error) => {
      console.log(error);
    });
  }
}
