











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Message } from 'element-ui';
import ShopCart from '@/model/ShopCart';
@Component
export default class InsShoppingCart extends Vue {
  isShow: boolean = false;
  shopCart: ShopCart = new ShopCart();
  toggleDialog () {
    this.isShow = !this.isShow;
  }

  closeDialog () {
    this.isShow = false;
  }

  // 获取购物车商品信息
  getShopCart () {
    // this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
  }

  // 删除购物车商品
  removeItem (id) {
    this.$Api.shoppingCart.removeItem(id).then((result) => {
      this.getShopCart();
    }).catch(() => {
      Message({
        message: '移除失败！',
        type: 'error'
      });
    });
  }
  mounted () {
    this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
  }
  get shopCarts () {
    return this.$store.state.shopCart;
  }
  @Watch('shopCarts')
  onShopCartsChange () {
    this.shopCarts.then((result) => { this.shopCart = result.ShopCart; }).catch((error) => { console.log(error); });
  }
  created () {
    // this.getShopCart();
  }
}
