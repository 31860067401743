
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import shopcart from './InsShoppingCart.vue';
import login from './InsLogin.vue';
// import search from './InsSearch.vue';

@Component({
  components: {
    shopcart,
    login
    // ,
    // search
  }
})
export default class InsHandleGroup extends Vue {
  private key:string = '';
  private changLange (lang) {
    this.$Api.member.setUILanguage(lang).then((result) => {
      this.$i18n.locale = lang;
      this.$Storage.set('locale', lang);
      this.Reload();
    }).catch((error) => {
      console.log(error);
    });
  }
  @Watch('key')
  searchKeyChange () {
    this.Shake(() => {
      this.$store.dispatch('setSearchKey', this.key);
      if (this.$route.name !== 'productSearch') {
        this.$router.push({
          path: '/product/search',
          name: 'productSearch',
          params: {
            key: this.key
          }
        });
      }
    }, 1000);
  }
}
