import { render, staticRenderFns } from "./InsHeader.vue?vue&type=template&id=6a0b31c7&scoped=true&"
import script from "./InsHeader.vue?vue&type=script&lang=ts&"
export * from "./InsHeader.vue?vue&type=script&lang=ts&"
import style0 from "./InsHeader.vue?vue&type=style&index=0&id=6a0b31c7&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0b31c7",
  null
  
)

export default component.exports